import { render, staticRenderFns } from "./BuddhistCalendar.vue?vue&type=template&id=3825c4b3&scoped=true"
import script from "./BuddhistCalendar.vue?vue&type=script&lang=js"
export * from "./BuddhistCalendar.vue?vue&type=script&lang=js"
import style0 from "./BuddhistCalendar.vue?vue&type=style&index=0&id=3825c4b3&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3825c4b3",
  null
  
)

export default component.exports